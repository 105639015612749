import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$( document ).ready(function() {
    if ($(window).width() < 992) {
        $('.m-research-list.-isCarousel').slick({
            dots: false,
            arrows: false,
            swipeToSlide: true,
            centerMode: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            controls: true,
            autoplay: false,
            autoplaySpeed: 4000,
            pauseOnHover: true,
            variableWidth: false,
            useAutoplayToggleButton: false,
            rows: 0,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        });
    }
});