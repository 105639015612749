import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$( document ).ready(function() {
    $('.m-podcasts-list.-isCarousel').slick({
        dots: false,
        arrows: false,
        swipeToSlide: true,
        centerMode: false,
        slidesToShow: 3.2,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        variableWidth: false,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 2.8,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2.5,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2.4,
                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 1.8,
                }
            },
            {
                breakpoint: 670,
                settings: {
                    slidesToShow: 1.5,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1.3,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });
});