import { default as $ } from 'jquery';

$(document).ready(function () {
    $("#searchButton").on("click", function(){
        $("#searchform").toggleClass("-show");
    });

    var header = $("#header");

    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 50) {
            header.addClass("-dark");
        } else {
            header.removeClass("-dark");
        }
    });    
    
    $('#mobileButton').on('click', function(){
        $('#header').toggleClass('-bg');
        $('.header-hamburger-bars').toggleClass('-active');
        $('#headerMobile').slideToggle();
    });
    
    $('.menu-item-has-children').on('click', function(){
        $(this).toggleClass('-show');
    });
});