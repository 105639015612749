// This file contains all parachute imports, e.g:
import './accordion/accordion';
import './animation/animation';
import './splitting/splitting';
import './header/header';
import './carousel/carousel';
import './carousel/cards';
import './carousel/training';
import './tabs/tabs';
import './carousel/steps';
import './carousel/podcasts';
import './carousel/research';
import './carousel/news';
import './footer/footer';
import './carousel/yellow';
import './carousel/videos';

// Publicly accessible methods for this build, useful for callbacks in script tags etc.
// Try and keep most things inside this webpack bundle though
import { publicExports } from './public-exports';
window._Parachute = publicExports || {};
