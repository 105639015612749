import { default as $ } from 'jquery';

$(document).ready(function () {
    $('#fontStandard').on('click', function(){
        $(body).removeClass('fontLarge').removeClass('fontXl');
    });

    $('#fontLarge').on('click', function(){
        $(body).addClass('fontLarge').removeClass('fontXl');
    });

    $('#fontXlarge').on('click', function(){
        $(body).addClass('fontXl').removeClass('fontLarge');
    });
});