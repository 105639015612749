import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$( document ).ready(function() {
    $('.m-list-carousel.-isCarousel').slick({
        dots: false,
        arrows: false,
        swipeToSlide: true,
        centerMode: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        variableWidth: false,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 3.5,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2.5,
                }
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2.2,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1.5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2.3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1.5,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1.2,
                }
            },
        ]
    });
});