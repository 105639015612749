import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$( document ).ready(function() {
    $('.m-featured-carousel.-isCarousel').slick({
        dots: true,
        arrows: false,
        appendDots: $('.m-featured-dots'),
        draggable: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        variableWidth: false,
        useAutoplayToggleButton: false,
        rows: 0,
    });
});