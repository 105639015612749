import 'jquery';
import { default as $ } from 'jquery';

$(window).on("load",function() {
    $(window).scroll(function() {
      var pageTop = $(document).scrollTop();
      var windowBottom = pageTop + $(window).height();

      $(".addAnimation").each(function() {
        var objectBottom = $(this).offset().top;
        
        if (objectBottom < windowBottom) {
          jQuery(this).addClass('m-fadeIn');
        };
      });
    
      $(".addSlide").each(function() {
        var objectBottom = $(this).offset().top;
        
        /* If the element is completely within bounds of the window, fade it in */
        if (objectBottom < windowBottom) {
          jQuery(this).addClass('m-slide');
        }
      });
    
      $(".cardAnimation").each(function() {
        var objectBottom = $(this).offset().top;
        
        if (objectBottom < windowBottom) {
          jQuery(this).find('.-card').addClass('cardFade');
        }
      });
    }).scroll(); //invoke scroll-handler on page-load
  });
