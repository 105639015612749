import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$( document ).ready(function() {
    $('.m-tabs-label:first').addClass('-active');
    $('.m-tabs-arrow:first').addClass('-inactive').removeClass('tab-arrow').removeClass('m-shake');
    $('.m-tabs-arrow:last').addClass('-inactive').removeClass('tab-arrow').removeClass('m-shake');

    $(".m-tabs-label").on("click", function(){
        $('.m-tabs-label').removeClass('-active');
        $(this).addClass('-active');
        var tab = $(this).data('tab');
        var tab = tab - 1;
        $('.m-tabs-carousel').slick('slickGoTo', tab);
    });

    $(".tab-arrow").on("click", function(){
        var tabNumber = $(this).data('arrows');

        if($(this).hasClass('tab-prev')) {
            var tabNumber = tabNumber - 1;
        } else {
            var tabNumber = tabNumber + 1;
        }
        
        $('.m-tabs-label').removeClass('-active');
        $('.m-tabs-label[data-tab = '+tabNumber+']').addClass('-active');
        var tabNumber = tabNumber - 1;
        $('.m-tabs-carousel').slick('slickGoTo', tabNumber);
        
        if ($(window).width() < 786) {
            $('.m-tabs-list').slick('slickGoTo', tabNumber);
        };
    });

    $('.m-tabs-carousel.-isCarousel').slick({
        dots: false,
        arrows: false,
        draggable: false,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        pauseOnHover: true,
        variableWidth: false,
        useAutoplayToggleButton: false,
        rows: 0,
    });

    if ($(window).width() < 786) {
        $('.m-tabs-list.-isCarousel').slick({
            dots: false,
            arrows: false,
            draggable: true,
            centerMode: false,
            slidesToShow: 2.5,
            slidesToScroll: 1,
            infinite: false,
            controls: true,
            autoplay: false,
            autoplaySpeed: 4000,
            pauseOnHover: true,
            variableWidth: true,
            useAutoplayToggleButton: false,
            rows: 0,
            responsive: [
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 2.2,
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 2,
                    }
                },
            ]
        });
    };
});