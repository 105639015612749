import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

var resize = false;

function slickify(carousel){
    if ($(window).width() < 576) {
        if(resize == false) {
            $(carousel).slick({
                dots: false,
                arrows: false,
                draggable: true,
                swipeToSlide: true,
                centerMode: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                controls: true,
                autoplay: false,
                pauseOnHover: true,
                variableWidth: false,
                useAutoplayToggleButton: false,
                rows: 0,
                mobileFirst: true,
                responsive: [
                    {
                        breakpoint: 576,
                        settings: {
                            settings: "unslick",
                        }
                    },
                    {
                        breakpoint: 400,
                        settings: {
                            slidesToShow: 1.3,
                        }
                    },
                ]
            });
            resize = true;
        }
    } else {
        $(carousel).slick("unslick");
        resize = false;
    };  
};

$( document ).ready(function() {
    if ($(window).width() < 576) {
        if($('.m-yellow-carousel.-isCarousel')[0]){
            var carousel = '.m-yellow-carousel.-isCarousel';
            slickify(carousel);

            window.onresize = function() {
                slickify(carousel);
            };
        };
    };
});
